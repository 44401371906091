import React, { useEffect, useState } from "react";
import {
	Button,
	useDisclosure,
	ModalOverlay,
	Modal,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	FormControl,
	FormLabel,
	Input,
	ModalFooter,
	Grid,
	Flex,
} from "@chakra-ui/react";
import { useCreateTrail } from "features/Orders/useCreateTrail";

export default function TrailFocus(props) {
	const { ...rest } = props;
	const { isOpen, onOpen, onClose } = useDisclosure();

	const initialRef = React.useRef();
	const finalRef = React.useRef();

	const [companyName, setCompanyName] = useState();
	const [email, setEmail] = useState();
	const [estimatedStorage, setEstimatedStorage] = useState();
	const [fingerprint, setFingerprint] = useState();
	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [phone, setPhone] = useState();
	const [uuid, setUuid] = useState();
	const [website, setWebsite] = useState();
	const [country, setCountry] = useState();
	const [role, setRole] = useState();
	const [rootPassword, setRootPassword] = useState();
	const [type, setType] = useState();

	const { createTrail, isLoading } = useCreateTrail();

	const handleCreateTrail = () => {
		createTrail({
			CompanyName: companyName,
			Email: email,
			EstStorage: estimatedStorage,
			Fingerprint: fingerprint,
			FirstName: firstName,
			LastName: lastName,
			Phone: phone,
			UUID: uuid,
			Website: website,
			Country: country,
			Role: role,
			RootPassword: rootPassword,
			Type: type,
		});
	};

	useEffect(() => {
		//
	}, []);

	return (
		<>
			<Button
				onClick={onOpen}
				style={{
					backgroundColor: "#7654ff",
					color: "white",
					borderRadius: "6px",
					padding: "13px 36px",
					height: "auto",
					border: "none",
					cursor: "pointer",
				}}
			>
				{rest.title}
			</Button>

			<Modal
				initialFocusRef={initialRef}
				finalFocusRef={finalRef}
				isOpen={isOpen}
				onClose={onClose}
				size="xl"
				m={4}
				p={3}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Create New Trial</ModalHeader>
					<ModalCloseButton />
					<ModalBody p={5}>
						<Grid templateColumns="repeat(2, 1fr)" gap={3}>
							<FormControl>
								<FormLabel>Company Name</FormLabel>
								<Input
									value={companyName}
									onChange={(e) => setCompanyName(e.target.value)}
									placeholder="Enter Campaign Name"
								/>
							</FormControl>
							<FormControl>
								<FormLabel>Country</FormLabel>
								<Input
									value={country}
									onChange={(e) => setCountry(e.target.value)}
									placeholder="Enter Company Name"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>Email</FormLabel>
								<Input
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Enter Email Address"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>Estimated Storage</FormLabel>
								<Input
									value={estimatedStorage}
									onChange={(e) => setEstimatedStorage(e.target.value)}
									placeholder="Enter Estimated Storage"
									inputMode="numeric"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>Fingerprint</FormLabel>
								<Input
									value={fingerprint}
									onChange={(e) => setFingerprint(e.target.value)}
									placeholder="Enter FingerPrint"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>First Name</FormLabel>
								<Input
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
									placeholder="Enter First Name"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>Last Name</FormLabel>
								<Input
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
									placeholder="Enter Last Name"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>Phone</FormLabel>
								<Input
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
									placeholder="Enter First Name"
									inputMode="numeric"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>Role</FormLabel>
								<Input
									value={role}
									onChange={(e) => setRole(e.target.value)}
									placeholder="Enter Preferred Language"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>Root Password</FormLabel>
								<Input
									value={rootPassword}
									onChange={(e) => setRootPassword(e.target.value)}
									placeholder="Enter Source"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>Type</FormLabel>
								<Input
									value={type}
									onChange={(e) => setType(e.target.value)}
									placeholder="Enter title"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>UUID</FormLabel>
								<Input
									value={uuid}
									onChange={(e) => setUuid(e.target.value)}
									placeholder="Enter title"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>Website</FormLabel>
								<Input
									value={website}
									onChange={(e) => setWebsite(e.target.value)}
									placeholder="Enter title"
								/>
							</FormControl>
						</Grid>
					</ModalBody>

					<ModalFooter>
						<Flex w="100%" justifyContent="flex-end">
							<Button onClick={onClose} borderRadius={5} p={"10px 30px"} mr={2}>
								Cancel
							</Button>
							<Button
								onClick={handleCreateTrail}
								disabled={isLoading}
								colorScheme="brand"
								ml={2}
								borderRadius={5}
								p={"10px 40px"}
							>
								Create
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
