import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { deleteDiskService } from "services/apiVm"; 
import { useHistory } from "react-router-dom";

export const useDeleteVMDisk = () => {
  const history = useHistory();

  const { mutate, isLoading, isSuccess, isError } = useMutation({
    mutationFn: (params) => deleteDiskService(params),
    onError: (error) => {
      const statusCode = error.response?.status || 500;
      if (statusCode === 401) {
        localStorage.removeItem("Titan_Access");
        history.replace("/auth/sign-in");
        toast.error("Unauthorized access. Please log in again.");
      } else if (statusCode === 500) {
        toast.error("Failed to delete disk due to server error.");
      } else {
        toast.error("Failed to delete disk.");
      }
    },
    onSuccess: (res) => {
      if (res.status === 200) {
        toast.success("Disk deleted successfully.");
      } else {
        const statusCode = res.status || 500;
        if (statusCode === 401) {
          localStorage.removeItem("Titan_Access");
          history.replace("/auth/sign-in");
          toast.error("Unauthorized access. Please log in again.");
        } else {
          toast.error("Error deleting disk.");
        }
      }
    },
  });

  return { deleteVMDisk: mutate, isLoading, isSuccess, isError };
};