import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { DashBoardTable } from "components/table/Table";
import React, { useState } from "react";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import CreateOrderForm from "components/OnboardForm/CreateOrderForm";
import DatabaseDetails from "./detailedview/DatabaseDetails";
import { useGetDatabases } from "features/Database/useGetDatabases";

const heads = [
  "Account Number",
  "CPU Count",
  "Database Type",
  "ID",
  "RAM",
  "Region",
  "Storage Class",
];

const Database = ({ isCreate, toggleCreate,toggleNavbar }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const { databases, isLoading, refetch } = useGetDatabases(searchTerm);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    toggleNavbar(false);
  };

  const handleBackToTable = () => {
    setSelectedRow(null);
    toggleNavbar(true);
  };

  return (
    <Box
      pt={
        isCreate ? { md: "45px" } : { base: "135px", md: "100px", xl: "100px" }
      }
      px={{ base: "15px", md: "15px" }}
    >
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        {!isCreate && !selectedRow && (
          <InputGroup width={{ base: "100%", md: "450px" }}>
            <InputLeftElement>
              <Icon as={SearchIcon} />
            </InputLeftElement>
            <Input
              placeholder="Search..."
              value={searchTerm}
              bg="white"
              color="navy.700"
              border="none"
              onChange={handleSearchChange}
              marginRight={4}
            />
          </InputGroup>
        )}
        {!selectedRow && (
					<Flex gap={4} justifyContent="flex-end" flex="1">
						<Button
							onClick={toggleCreate}
							variant="solid"
							size="md"
							px={"1.2rem"}
							py={"1.4rem"}
							borderRadius={5}
							bg={isCreate ? "#FF6060" : "#422AFB"}
							color="white"
							fontWeight="light"
							_hover={{ bg: isCreate ? "#FF6060" : "#422AFB", opacity: 0.8 }}
							_active={{ bg: isCreate ? "#FF6060" : "#422AFB", opacity: 0.6 }}
						>
							{isCreate ? "Cancel" : "Create New Database"}
						</Button>
					</Flex>
				)}
      </Flex>
      { selectedRow?(
        <DatabaseDetails data={selectedRow} onBack={handleBackToTable}/>
      ):
        isCreate ? (
          <CreateOrderForm
          selectedOption="Relational Database"
          setCreated={toggleCreate}
        />
      ) : (
        <DashBoardTable heads={heads} data={databases} onRowClick={handleRowClick} />
      )}
    </Box>
  );
};

export default Database;