import React from "react";
// Icon imports 
import { Icon} from "@chakra-ui/react";
import { ReactComponent as PersonIcon } from './assets/icons/person.svg';
import { ReactComponent as FlagIcon } from './assets/icons/flag.svg';
import { ReactComponent as LockIcon } from './assets/icons/lock.svg';
import { ReactComponent as StatsIcon } from './assets/icons/stats.svg';
import { ReactComponent as ExportIcon } from './assets/icons/export.svg';
import { ReactComponent as StorageIcon } from './assets/icons/storage.svg';
import { ReactComponent as DatabaseIcon } from './assets/icons/database.svg';
// Admin Imports
// Auth Imports
// import SignInCentered from "views/auth/signIn";
import { IoLogOutOutline } from "react-icons/io5";
import Orders from "views/admin/default/Orders";
import Invoices from "views/admin/default/Invoices";
import Quotes from "views/admin/default/Quotes";
import Customers from "views/admin/default/Customers";
import Tickets from "views/admin/default/Tickets";
import Usage from "views/admin/default/Usage";
import { Logout } from "features/Authentication/Logout";
import Vm from "views/admin/default/Vm";
import Storage from "views/admin/default/Storage";
import Firewall from "views/admin/default/Firewall";
import Vn from "views/admin/default/Vn";
import Database from "views/admin/default/Database";

const routes = [
	{
		name: "Orders",
		layout: "/admin",
		path: "/orders",
		icon: (
		  <Icon
			as={PersonIcon}
			style={{
			  display: "flex",
			  justifyContent: "center",
			  alignItems: "center",
			}}
			width="20px"
			height="20px"
			color="inherit"
		  />
		),
		component: Orders,
		description: "You can find the summary of all your orders here..."
	  },
	  {
		name: "Quote",
		layout: "/admin",
		path: "/quote",
		icon: (
		  <Icon
			as={FlagIcon}
			style={{
			  display: "flex",
			  justifyContent: "center",
			  alignItems: "center",
			}}
			width="20px"
			height="20px"
			color="inherit"
		  />
		),
		component: Quotes,
	  },
	  {
		name: "Customers",
		layout: "/admin",
		path: "/customer",
		icon: (
		  <Icon
			as={PersonIcon}
			style={{
			  display: "flex",
			  justifyContent: "center",
			  alignItems: "center",
			}}
			width="20px"
			height="20px"
			color="inherit"
		  />
		),
		component: Customers,
		description: "You can find the summary of all your customers here...",
	  },
	  {
		name: "Invoices",
		layout: "/admin",
		path: "/invoices",
		icon: (
		  <Icon
			as={LockIcon}
			style={{
			  display: "flex",
			  justifyContent: "center",
			  alignItems: "center",
			}}
			width="20px"
			height="20px"
			color="inherit"
		  />
		),
		component: Invoices,
	  },
	  {
		name: "Usage",
		layout: "/admin",
		path: "/usage",
		icon: (
		  <Icon
			as={StatsIcon}
			style={{
			  display: "flex",
			  justifyContent: "center",
			  alignItems: "center",
			}}
			width="20px"
			height="20px"
			color="inherit"
		  />
		),
		component: Usage,
	  },
	  {
		name: "Tickets",
		layout: "/admin",
		path: "/tickets",
		icon: (
		  <Icon
			as={ExportIcon}
			style={{
			  display: "flex",
			  justifyContent: "center",
			  alignItems: "center",
			}}
			width="20px"
			height="20px"
			color="inherit"
		  />
		),
		component: Tickets,
	  },
	  {
		name: "Storage",
		layout: "/admin",
		path: "/storage",
		icon: (
		  <Icon
			as={StorageIcon}
			style={{
			  display: "flex",
			  justifyContent: "center",
			  alignItems: "center",
			}}
			width="20px"
			height="20px"
			color="inherit"
		  />
		),
		collapsible: true,
		component: Storage,
	  },
	  {
		name: "Databases",
		layout: "/admin",
		path: "/database",
		icon: (
		  <Icon
			as={DatabaseIcon}
			style={{
			  display: "flex",
			  justifyContent: "center",
			  alignItems: "center",
			}}
			width="20px"
			height="20px"
			color="inherit"
		  />
		),
		collapsible: true,
		component: Database,
	  },
	  {
		name: "Virtual Machines",
		layout: "/admin",
		path: "/vm",
		collapsible: true,
		component: Vm,
	  },
	  {
		name: "Virtual Networks",
		layout: "/admin",
		path: "/vn",
		collapsible: true,
		component: Vn,
	  },
	  {
		name: "Firewalls",
		layout: "/admin",
		path: "/firewall",
		collapsible: true,
		component: Firewall,
	  },
	{
		name: "Logout",
		layout: "/admin",
		path: "/logout",
		icon: (
			<Icon
				as={IoLogOutOutline}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
				width="20px"
				height="20px"
				color="inherit"
			/>
		),
		component: Logout,
	},
];

export default routes;