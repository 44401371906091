import React, { useEffect, useState } from "react"
import { Box, Text, Select, Input, Link, Icon, FormControl, FormErrorMessage, Button } from "@chakra-ui/react"
import { ReactComponent as DropdownIcon } from "../../../../assets/icons/dropdown.svg"
import { checkAccountService } from "../../../../services/apiAccount"
import { toast } from "@chakra-ui/react"
const AccountProductInfo = ({ formData, updateFormData, orderType, setShowCreateCustomer, setStepValidation,showErrors,setShowErrors }) => {
  const [accountError, setAccountError] = useState("")
  const [isValidating, setIsValidating] = useState(false)
  const [isAccountValid, setIsAccountValid] = useState(false)
  const [errors, setErrors] = useState({
    distributor: "",
    accountNumber: "",
    adminUsername: "",
    adminPassword: "",
  })

  const handleFieldChange = (e) => {
    const { name, value } = e.target

    if (name.includes(".")) {
      const [parent, child] = name.split(".")
      updateFormData({
        [parent]: {
          ...formData[parent],
          [child]: value,
        },
      })
    } else {
      updateFormData({ [name]: value })
    }
  }

  const handleCheckAccount = async () => {
    setIsValidating(true)
    setAccountError("")
    try {
      const response = await checkAccountService({ accountNumber: formData.virtualMachine?.accountNumber })
      if (response.data.data.StatusCode === 200) {
        setAccountError("")
        setIsAccountValid(true)
      } else {
        setAccountError("Invalid account number")
        toast.error("Invalid account number")
        setIsAccountValid(false)
      }
    } catch (error) {
      setAccountError("Error validating account number")
      setIsAccountValid(false)
    }
    setIsValidating(false)
  }

  const handleNextStepValidation = () => {
    const newErrors = {
      distributor: formData.distributor ? "" : "Distributor is required",
      accountNumber: formData.virtualMachine?.accountNumber && isAccountValid ? "" : "Valid account number is required",
    }

    if (orderType === "Database") {
      newErrors.adminUsername = formData.database?.adminUsername ? "" : "Admin Username is required"
      newErrors.adminPassword = formData.database?.adminPassword ? "" : "Admin Password is required"
    }

    setErrors(newErrors)

    const isValid =
      formData.distributor &&
      formData.virtualMachine?.accountNumber &&
      isAccountValid &&
      (orderType !== "Database" ||
        (formData.database?.adminUsername && formData.database?.adminPassword))

    setStepValidation((prev) => ({ ...prev, 1: isValid }))
  }

  useEffect(() => {
    if (isAccountValid) {
      handleNextStepValidation()
    }
  }, [isAccountValid])

  useEffect(() => {
    if (orderType === "VM" && setStepValidation && showErrors) {
      handleNextStepValidation()
    }
  }, [formData, orderType, showErrors])

  return (
    <Box width="100%">
      <Box mb={6}>
        <Text fontWeight="bold" fontSize="md" mb={2}>
          Account Information
        </Text>
        <FormControl isInvalid={!!errors.distributor && showErrors}>
          <Select
            name="distributor"
            value={formData.distributor}
            placeholder="Select Distributor"
            onChange={handleFieldChange}
            mb={2}
            bg="white"
            icon={<Icon as={DropdownIcon} />}
          >
            <option value="Direct">Direct</option>
            <option value="GTS">GTS</option>
            <option value="Clarus">Clarus</option>
            <option value="Xaas1">Xaas1</option>
          </Select>
          <FormErrorMessage>{errors.distributor}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={(!!errors.accountNumber || !!accountError) && showErrors}>
          <Input
            name="virtualMachine.accountNumber"
            type="text"
            value={formData.virtualMachine?.accountNumber || ""}
            onChange={handleFieldChange}
            placeholder="Account Number"
            mb={2}
            bg="white"
          />
          <Button
            onClick={handleCheckAccount}
            isLoading={isValidating}
            mt={2}
            mb={2}
            disabled={!formData.virtualMachine?.accountNumber}
          >
            Check Account
          </Button>
          <FormErrorMessage>{errors.accountNumber || accountError}</FormErrorMessage>
        </FormControl>

        {orderType === "Database" && (
          <>
            <FormControl isInvalid={!!errors.adminUsername && showErrors}>
              <Input
                name="database.adminUsername"
                type="text"
                value={formData.database?.adminUsername || ""}
                onChange={handleFieldChange}
                placeholder="Admin Username"
                mb={2}
                bg="white"
              />
              <FormErrorMessage>{errors.adminUsername}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.adminPassword && showErrors}>
              <Input
                name="database.adminPassword"
                type="password"
                value={formData.database?.adminPassword || ""}
                onChange={handleFieldChange}
                placeholder="Admin Password"
                mb={2}
                bg="white"
              />
              <FormErrorMessage>{errors.adminPassword}</FormErrorMessage>
            </FormControl>
          </>
        )}

        <Text textAlign="right" mb={6}>
          Don't have the customer yet?{" "}
          <Link
            onClick={() => setShowCreateCustomer(true)}
            textDecoration="underline"
            _hover={{ textDecoration: "underline" }}
          >
            Create one
          </Link>
        </Text>
      </Box>
    </Box>
  )
}

export default AccountProductInfo