import React, { useEffect, useState } from "react"
import {
  Box,
  Input,
  Checkbox,
  Flex,
  Text,
  Select,
  Icon,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react"
import { ReactComponent as DropdownIcon } from "../../../../assets/icons/dropdown.svg"

const NetworkConfiguration = ({ formData, orderType, updateFormData, setStepValidation,showErrors}) => {
  const [errors, setErrors] = useState({
    region: "",
    subnetMask: "",
    ipv4: "",
    dhcpStartIP: "",
    dhcpEndIP: "",
    gatewayIP: "",
    gatewaySubnet: "",
    bandwidth: "",
  })
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleFieldChange = (e) => {
    const { name, value, type, checked } = e.target

    if (name === "virtualMachine.networkDevices.0.subnetMask") {
      validateSubnetMask(value)
    }

    if (name === "virtualMachine.networkDevices.0.ipv4") {
      validateIpAddress(value)
    }

    updateFormData((prevData) => {
      const newData = { ...prevData }

      if (name.includes(".")) {
        const keys = name.split(".")
        let current = newData

        for (let i = 0; i < keys.length - 1; i++) {
          if (keys[i] === "virtualMachine") {
            current.virtualMachine = { ...current.virtualMachine }
            current = current.virtualMachine
          } else {
            current[keys[i]] = { ...current[keys[i]] }
            current = current[keys[i]]
          }
        }

        current[keys[keys.length - 1]] = type === "checkbox" ? checked : value
      } else if (name.startsWith("virtualMachine.")) {
        newData.virtualMachine = {
          ...newData.virtualMachine,
          [name.split(".")[1]]: type === "checkbox" ? checked : value,
        }
      } else {
        newData[name] = type === "checkbox" ? checked : value
      }

      return newData
    })
  }

  const validateSubnetMask = (value) => {
    const subnetRegex = /^(255\.){3}(0|128|192|224|240|248|252|254|255)$/
    if (!subnetRegex.test(value) && isSubmitted) {
      setErrors((prev) => ({ ...prev, subnetMask: "Invalid subnet mask format" }))
      return false
    }
    setErrors((prev) => ({ ...prev, subnetMask: "" }))
    return true
  }

  const validateIpAddress = (value) => {
    const ipRegex =
      /^(10\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|((172\.(1[6-9]|2[0-9]|3[0-1]))\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|(192\.168\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$/
    if (!ipRegex.test(value) && isSubmitted) {
      setErrors((prev) => ({
        ...prev,
        ipv4: "Invalid local IP address. Must be in the range of 10.0.0.0 – 10.255.255.255, 172.16.0.0 – 172.31.255.255, or 192.168.0.0 – 192.168.255.255",
      }))
      return false
    }
    setErrors((prev) => ({ ...prev, ipv4: "" }))
    return true
  }

  const handleNextStepValidation = () => {
    const newErrors = {
      region: formData.virtualMachine?.region ? "" : "Region is required",
      subnetMask: formData.virtualMachine?.networkDevices?.[0]?.subnetMask ? "" : "Subnet Mask is required",
      ipv4: formData.virtualMachine?.networkDevices?.[0]?.ipv4 ? "" : "IPv4 Address is required",
      dhcpStartIP:
        formData.virtualMachine?.networkDevices?.[0]?.useDHCP &&
        !formData.virtualMachine?.networkDevices?.[0]?.dhcpStartIP
          ? "DHCP Start IP is required when using DHCP"
          : "",
      dhcpEndIP:
        formData.virtualMachine?.networkDevices?.[0]?.useDHCP &&
        !formData.virtualMachine?.networkDevices?.[0]?.dhcpEndIP
          ? "DHCP End IP is required when using DHCP"
          : "",
      gatewayIP:
        formData.virtualMachine?.networkDevices?.[0]?.enableGateway &&
        !formData.virtualMachine?.networkDevices?.[0]?.gatewayIP
          ? "Gateway IP is required when gateway is enabled"
          : "",
      gatewaySubnet:
        formData.virtualMachine?.networkDevices?.[0]?.enableGateway &&
        !formData.virtualMachine?.networkDevices?.[0]?.gatewaySubnet
          ? "Gateway Subnet is required when gateway is enabled"
          : "",
      bandwidth: formData.virtualMachine?.networkDevices?.[0]?.bandwidth ? "" : "Bandwidth is required",
    };
  
    setErrors(newErrors);
  
    const isValid = Object.values(newErrors).every((error) => error === "") && 
                    !errors.subnetMask && !errors.ipv4;
  
    setStepValidation((prev) => ({ ...prev, 3: isValid }));
  };
  

  useEffect(() => {
    if (orderType === "VM") {
      handleNextStepValidation();
    }
  }, [formData, orderType]);
  
  return (
    <Box py={5} width="100%">
      <Box>
        <Text fontSize="md" fontWeight="bold" mb={4}>
          CIDR
        </Text>
        <FormControl isInvalid={!!errors.region && showErrors} mb={4}>
          <Select
            placeholder="Region Selection"
            name="virtualMachine.region"
            value={formData.virtualMachine?.region || ""}
            onChange={handleFieldChange}
            bg="white"
            icon={<Icon as={DropdownIcon} />}
          >
            <option value="us-multi-region">us-multi-region</option>
            <option value="us-south-1">us-south-1</option>
            <option value="us-west-1">us-west-1</option>
          </Select>
          <FormErrorMessage>{errors.region}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.subnetMask && showErrors} mb={4}>
          <FormLabel htmlFor="subnetMask">Subnet Mask</FormLabel>
          <Input
            id="subnetMask"
            placeholder="255.255.255.0"
            name="virtualMachine.networkDevices.0.subnetMask"
            value={formData.virtualMachine?.networkDevices?.[0]?.subnetMask || ""}
            onChange={handleFieldChange}
            backgroundColor="white"
            required
          />
          <FormErrorMessage>{errors.subnetMask}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.ipv4 &&showErrors} mb={4}>
          <FormLabel htmlFor="ipv4">IPv4 Address</FormLabel>
          <Input
            id="ipv4"
            placeholder="192.168.1.1"
            name="virtualMachine.networkDevices.0.ipv4"
            value={formData.virtualMachine?.networkDevices?.[0]?.ipv4 || ""}
            onChange={handleFieldChange}
            backgroundColor="white"
            required
          />
          <FormErrorMessage>{errors.ipv4}</FormErrorMessage>
        </FormControl>

        <Checkbox
          name="virtualMachine.networkDevices.0.useDHCP"
          isChecked={formData.virtualMachine?.networkDevices?.[0]?.useDHCP || false}
          onChange={handleFieldChange}
          mb={4}
          mr={20}
        >
          Use DHCP
        </Checkbox>

        {formData.virtualMachine?.networkDevices?.[0]?.useDHCP && (
          <Flex gap={2} mb={4}>
            <FormControl isInvalid={ !!errors.dhcpStartIP && showErrors}>
              <Input
                placeholder="DHCP Start IP"
                name="virtualMachine.networkDevices.0.dhcpStartIP"
                value={formData.virtualMachine?.networkDevices?.[0]?.dhcpStartIP || ""}
                onChange={handleFieldChange}
                backgroundColor="white"
              />
              <FormErrorMessage>{errors.dhcpStartIP}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.dhcpEndIP && showErrors}>
              <Input
                placeholder="DHCP End IP"
                name="virtualMachine.networkDevices.0.dhcpEndIP"
                value={formData.virtualMachine?.networkDevices?.[0]?.dhcpEndIP || ""}
                onChange={handleFieldChange}
                backgroundColor="white"
              />
              <FormErrorMessage>{errors.dhcpEndIP}</FormErrorMessage>
            </FormControl>
          </Flex>
        )}
        <Text fontSize="md" fontWeight="bold" my={4}>
          Miscellaneous
        </Text>
        <FormControl isInvalid={!!errors.bandwidth && showErrors} mb={4}>
          <Select
            placeholder="Bandwidth"
            name="virtualMachine.networkDevices.0.bandwidth"
            value={formData.virtualMachine?.networkDevices?.[0]?.bandwidth || ""}
            
            onChange={handleFieldChange}
            backgroundColor="white"
          >
            <option value="test1">test 1</option>
            <option value="test2">test 2</option>
          </Select>
          <FormErrorMessage>{errors.bandwidth}</FormErrorMessage>
        </FormControl>

        <Flex gap={4} mb={4} width="65%" justifyContent="space-between">
          <Checkbox
            name="virtualMachine.networkDevices.0.addFirewall"
            isChecked={formData.virtualMachine?.networkDevices?.[0]?.addFirewall || false}
            onChange={handleFieldChange}
          >
            Add Firewall
          </Checkbox>

          <Checkbox
            name="virtualMachine.networkDevices.0.addVPN"
            isChecked={formData.virtualMachine?.networkDevices?.[0]?.addVPN || false}
            onChange={handleFieldChange}
          >
            Add VPN
          </Checkbox>
        </Flex>
      </Box>
    </Box>
  )
}

export default NetworkConfiguration