import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { createDiskService } from "services/apiVm";
import { useHistory } from "react-router-dom";

export const usePostVMDisk = () => {
  const history = useHistory();
  
  const { mutate, isLoading, isSuccess, isError } = useMutation({
    mutationFn: ({ vmid, diskData }) => createDiskService(vmid, diskData),
    onError: (error) => {
      if (error.response?.status === 401) {
        localStorage.removeItem("Titan_Access");
        history.replace("/auth/sign-in");
        toast.error("Unauthorized access. Please login again.");
      } else {
        toast.error(error.response?.data?.message || "Failed to create disk.");
      }
    },
    onSuccess: (res) => {
      if (res && (res.status === 200 || res.status === 201)) {
        toast.success("Disk created successfully!");
      } else {
        toast.error(res?.data?.message || "Unexpected error occurred.");
      }
    },
  });

  const createDisk = (vmid, diskData) => {
    mutate({ vmid, diskData });
  };

  return { createDisk, isLoading, isSuccess, isError };
};