import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useEffect, useRef } from "react";
import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuItemOption,
	MenuGroup,
	MenuOptionGroup,
	Input,
	MenuDivider,
	Button,
	useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { LuFilter } from "react-icons/lu";
import { useHistory, useLocation } from "react-router-dom";
import { usePartnerId } from "contexts/PartnerIdContext";

export const UsageFilterMenu = () => {
	const currentDate = new Date();
	const oneMonthBefore = new Date(currentDate);
	oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);
	oneMonthBefore.setDate(1);
	const omb_date = oneMonthBefore.toISOString().split("T")[0];

	const history = useHistory();
	const location = useLocation();

	const initialRef = useRef();
	const finalRef = useRef();

	const { onOpen, onClose, isOpen } = useDisclosure();

	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState(
		new Date().toISOString().split("T")[0]
	);
	const [accountNumber, setAccountNumber] = useState("");

	useEffect(() => {
		setStartDate(omb_date);
	}, [omb_date]);

	function handleReset() {
		setEndDate(new Date().toISOString().split("T")[0]);
		setAccountNumber("");
		// Reset URL to remove query params
		history.push(location.pathname);
		onClose();
	}

	function handleFilter() {
		const filterObject = {
			startDate,
			endDate,
			accountNumber,
		};

		// Construct query string
		const queryParams = new URLSearchParams(filterObject);
		const queryString = queryParams.toString();

		// Update URL with query string
		history.push({
			pathname: location.pathname,
			search: queryString,
		});

		onClose();
	}

	return (
		<Menu
			initialFocusRef={initialRef}
			finalFocusRef={finalRef}
			isOpen={isOpen}
			onClose={onClose}
		>
			<MenuButton
				borderRadius="0.4rem"
				as={Button}
				onClick={onOpen}
				style={{
					color: "black",
					borderRadius: "6px",
					padding: "13px 36px",
					height: "auto",
					border: "none",
					cursor: "pointer",
				}}
			>
				<div
					style={{
						display: "flex",
						gap: "20px",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					Filter Usage
					<LuFilter />
				</div>
			</MenuButton>
			<MenuList
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "1rem",
					padding: "0rem 1rem",
				}}
			>
				<MenuGroup title="AccountNumber">
					<Input
						type="string"
						placeholder="Enter Account Number"
						value={accountNumber}
						onChange={(e) => setAccountNumber(e.target.value)}
					/>
				</MenuGroup>
				<MenuGroup title="StartDate">
					<Input
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
					/>
				</MenuGroup>
				<MenuGroup title="EndDate">
					<Input
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
					/>
				</MenuGroup>
				<Button
					rightIcon={<ArrowForwardIcon />}
					style={{
						marginBottom: "1rem",
						gridColumn: "1 / 2",
						color: "white",
						borderRadius: "6px",
						padding: "0.4rem 2rem",
						width: "100%",
						height: "auto",
						border: "none",
						cursor: "pointer",
						backgroundColor: "#7654ff",
					}}
					onClick={handleFilter}
				>
					Apply
				</Button>
				<Button
					style={{
						marginBottom: "1rem",
						gridColumn: "2 / 3",
						color: "white",
						borderRadius: "6px",
						padding: "0.4rem 2rem",
						width: "100%",
						height: "auto",
						border: "none",
						cursor: "pointer",
						backgroundColor: "#ff5757",
					}}
					onClick={handleReset}
				>
					Reset
				</Button>
			</MenuList>
		</Menu>
	);
};
