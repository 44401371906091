import React, { useState } from "react";
import {
  Text,
  Box,
  Link,
  Icon,
  Flex,
  Circle,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import BasicInformationForm from "./forms/customer/BasicInformationForm";
import BillingDetailsForm from "./forms/customer/BillingDetailsForm";

const CreateCustomerForm = ({ setCreated, onComplete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    basic_information: null,
    billing_details: null,
  });

  const handleBack = () => {
    if (step === 1) {
      setCreated(false)
    } else {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const updateFormData = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  const handleFormCompletion = () => {
    onOpen();
  };

  const handleStepClick = (newStep) => {
    if (newStep < step || (newStep === 2 && formData.basic_information)) {
      setStep(newStep);
    }
  };

  const handleDashboardRedirect = () => {
    onClose();
    onComplete();
  };

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <BasicInformationForm
            setStep={setStep}
            formData={formData}
            updateFormData={updateFormData}
          />
        );
      case 2:
        return (
          <BillingDetailsForm
            setStep={setStep}
            formData={formData}
            updateFormData={updateFormData}
            onComplete={handleFormCompletion}
          />
        );
      default:
        return <Text>Form Completed</Text>;
    }
  };

  return (
    <Box py={2}>
      <Box position="absolute" top="2rem">
        <Link py={3} display="flex">
          {"Dashboard → Customers → "}
          <Text fontWeight="bold"> Create New Customer</Text>
        </Link>
        <Box display="flex" alignItems="center">
          <Link
            href="#"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={handleBack}
          >
            <Box
              backgroundColor="#363853"
              borderRadius="50%"
              p={1.5}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon as={MdArrowBack} color="white" w={5} h={5} />
            </Box>
          </Link>
          <Text borderRadius="inherit" fontWeight="bold" fontSize="30px" ml={3} color={"#363853"}>
            Create New Customer
          </Text>
        </Box>
      </Box>

      <Box
        justifyContent="center"
        alignItems="center"
        width="50%"
        position="relative"
        my={2}
      >
        <Flex alignItems="center" flex="1" mx="7">
          <Circle color="white" bgColor="#422AFB" size="8" onClick={() => handleStepClick(1)} cursor="pointer">1</Circle>
          <Box
            flex="1"
            height="2px"
            bgColor={step === 2 ? "#422AFB" : "gray"}
            opacity={step === 2 ? 1 : 0.2}
          />
          <Circle
            color="white"
            bgColor={step === 2 ? "#422AFB" : "gray"}
            opacity={step === 2 ? 1 : 0.2}
            size="8"
            onClick={() => handleStepClick(2)}
            cursor={formData.basic_information ? "pointer" : "not-allowed"}
          >2</Circle>
        </Flex>

        <Flex flex="1" justifyContent="space-between" mt={4} ml={1}>
          <Box textAlign="center">
            <Text color="black" fontWeight="bold">Step 1</Text>
            <Text>Basic <br /> Information</Text>
          </Box>
          <Box
            textAlign="center"
            color={step === 2 ? "black" : "gray"}
            opacity={step === 2 ? 1 : 0.6}
          >
            <Text fontWeight="bold">Step 2</Text>
            <Text>Billing Details</Text>
          </Box>
        </Flex>
      </Box>

      <Box>{renderForm()}</Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent alignItems="center" py={3} px={1} borderRadius={16}>
          <ModalHeader textAlign="center">
            <Icon as={SuccessIcon} w={20} h={20} color="#20bf55" />
            <Text fontWeight="bold" fontSize="3xl">Success!</Text>
          </ModalHeader>
          <ModalBody textAlign="center">
            <Text>Customer was Created Successfully</Text>
          </ModalBody>
          <ModalFooter w="full">
            <Button
              color={"white"}
              bgColor="#422AFB"
              w="full"
              borderRadius={10}
              _hover={{ bg: "#3b24da" }}
              onClick={handleDashboardRedirect}
            >
              Back to Dashboard
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CreateCustomerForm;