import React, { useState } from "react";
import {
  Box,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  Button,
} from "@chakra-ui/react";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { DashBoardTable } from "components/table/Table";
import VmDetails from "./detailedview/VmDetails";
import CreateOrderForm from "components/OnboardForm/CreateOrderForm";
import { useGetVirtualMachines } from "features/Vm/useGetVirtualMachines";

const heads = [
  "Account Number",
  "Name",
  "Region",
  "CPU",
  "vCPUs",
  "RAM",
  "Operating System",
];

const Vm = ({ isCreate, toggleCreate, toggleNavbar }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const { virtualMachines, isLoading, refetch } = useGetVirtualMachines(searchTerm);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    refetch();
  };

  const handleRowClick = (accountNumber) => {
    const selectedVm = virtualMachines.find(
      (vm) => vm.accountNumber === accountNumber
    );
    setSelectedRow(selectedVm);
    toggleNavbar(false);
  };

  const handleBackToTable = () => {
    setSelectedRow(null);
    toggleNavbar(true);
  };

  const handleCreate = () => {
    toggleCreate(true);
  };

  const handleCancel = () => {
    toggleCreate(false);
  };

  const formatVirtualMachineDataForTable = (vm) => ({
    "Account Number": vm.accountNumber,
    "Name": "",
    "Region": vm.region,
    "CPU": `${vm.cpu.cpuMake + " " + vm.cpu.cpuGeneration}`,
    "CPU Cores": vm.cpu.vCPU,
    "RAM": `${vm.memoryAmount} GB`,
    "Operating System": vm.operatingSystem,
  });

  const formattedDataForTable = virtualMachines
    ? virtualMachines.map(formatVirtualMachineDataForTable)
    : [];

  return (
    <Box
      pt={isCreate ? { md: "45px" } : { base: "135px", md: "100px", xl: "100px" }}
      px={{ base: "15px", md: "15px" }}
    >
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        {!isCreate && !selectedRow && (
          <InputGroup width={{ base: "100%", md: "450px" }}>
            <InputLeftElement>
              <Icon as={SearchIcon} />
            </InputLeftElement>
            <Input
              placeholder="Search Account Number..."
              value={searchTerm}
              bg="white"
              color="navy.700"
              border="none"
              onChange={handleSearchChange}
              marginRight={4}
            />
          </InputGroup>
        )}
        {!selectedRow && (
          <Flex gap={4} justifyContent="flex-end" flex="1">
            <Button
              onClick={isCreate ? handleCancel : handleCreate}
              variant="solid"
              size="md"
              px={"1.2rem"}
              py={"1.4rem"}
              borderRadius={5}
              bg={isCreate ? "#FF6060" : "#422AFB"}
              color="white"
              fontWeight="light"
              _hover={{ bg: isCreate ? "#FF6060" : "#422AFB", opacity: 0.8 }}
              _active={{ bg: isCreate ? "#FF6060" : "#422AFB", opacity: 0.6 }}
            >
              {isCreate ? "Cancel" : "Create New VM"}
            </Button>
          </Flex>
        )}
      </Flex>

      {selectedRow ? (
        <VmDetails data={selectedRow} onBack={handleBackToTable} />
      ) : isCreate ? (
        <CreateOrderForm
          selectedOption="Virtual Machine"
          setCreated={toggleCreate}
        />
      ) : isLoading ? (
        <div>Loading...</div>
      ) : (
        <DashBoardTable
          heads={heads}
          data={formattedDataForTable || []}
          onRowClick={(row) => handleRowClick(row["Account Number"])}
        />
      )}
    </Box>
  );
};

export default Vm;