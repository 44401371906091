import React, { useState, useEffect } from "react";
import {
  Center,
  Spinner,
  Box,
  Text,
  Button,
  Flex,
  Link,
  Divider,
  Circle,
} from "@chakra-ui/react";
import AccountProductInfo from "./AccountProductInfo";
import SystemSetup from "./SystemSetup";
import BillingInformation from "./BillingInformation";
import OrderSuccessOrFail from "./OrderSuccessOrFail";
import { useCreateOrder } from "features/Onboarding/useCreateOrder";
import { MdArrowBack } from "react-icons/md";
import OrderSummary from "components/OnboardForm/card/OrderSummary";
import StepTracker from "components/breadcrumb/StepTracker";
import CreateCustomerForm from "components/OnboardForm/CreateCustomerForm";

const stepForm = [
  { step: 1, form: AccountProductInfo, name: "Account & Product Information" },
  { step: 2, form: SystemSetup, name: "System Setup" },
  { step: 3, form: BillingInformation, name: "Billing Information" },
];

const OrderACS = ({ setCreated }) => {
  const [step, setStep] = useState(Number(sessionStorage.getItem("step")) || 1);
  const [formData, setFormData] = useState(
    {
      distributor: "Direct",
      region: "us-east-1",
      virtualMachine: {
        accountNumber: "",
        allowedSpecs: {
          gpuEnabled: 0,
          maxCPU: 0,
          maxDisks: 0,
          maxGPU: 0,
          maxRAM: 0,
          maxStorage: 0,
          minCPU: 0,
          minDisks: 0,
          minGPU: 0,
          minRAM: 0,
          minStorage: 0,
        }
    }}
  );

  const { isLoading, createOrder, isSuccess, isError } = useCreateOrder();
  const [modalOpen, setModalOpen] = useState(false);
  const [showCreateCustomer, setShowCreateCustomer] = useState(false);

  const handleNext = () => {
    if (step === 3) {
      setModalOpen(true);
    } else {
      setStep((prev) => Math.min(prev + 1, stepForm.length));
    }
  };

  const handleSubmit = () => {
    sessionStorage.removeItem("formData");
    sessionStorage.removeItem("step");
    createOrder(formData);
  };

  useEffect(() => {
    if (isSuccess || isError) {
      setCreated(true);
    }
  }, [isSuccess, isError, setCreated]);

  useEffect(() => {
    if (step === 4) {
      handleSubmit();
    } else {
      sessionStorage.setItem("formData", JSON.stringify(formData));
      sessionStorage.setItem("step", step);
    }
    return () => {
      sessionStorage.clear();
    };
  }, [step]);

  const updateFormData = (updatedData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };

  const handleBack = () => {
    if (step === 1) {
      setCreated();
    } else {
      setStep((prev) => Math.max(prev - 1, 1));
    }
  };

  return (
    <>
      {isLoading ? (
        <Center height="100vh">
          <Spinner size="lg" />
        </Center>
      ) : isSuccess || isError ? (
        <OrderSuccessOrFail
          isError={isError}
          isSuccess={isSuccess}
          setStep={setStep}
        />
      )  : showCreateCustomer ? (
                    <CreateCustomerForm setCreated={setShowCreateCustomer} onComplete={setShowCreateCustomer}/>
            ) : (
        <Flex
          direction={step !== 1 ? "row" : "column"}
          width="100%"
          height="100%"
        >
          <Box
            height="100%"
            width={step !== 1 ? "65%" : "100%"}
            pr={step !== 1 ? 4 : 0}
          >
            <Box position="absolute" top="2rem">
              <Link py={3} display="flex">
                {"Dashboard → Pages → Orders "}
                <Text fontWeight="bold">{"→ Create Order → Storage"}</Text>
              </Link>
              <Box display="flex" alignItems="center">
                <Link
                  href="#"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  onClick={handleBack}
                >
                  <Box
                    backgroundColor="#363853"
                    borderRadius="50%"
                    p={1.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MdArrowBack color="white" w={5} h={5} />
                  </Box>
                </Link>
                <Text
                  borderRadius="inherit"
                  fontWeight="bold"
                  fontSize="30px"
                  ml={3}
                >
                  {stepForm[step - 1].name}
                </Text>
              </Box>
            </Box>
            
            <StepTracker stepForm={stepForm} step={step} setStep={setStep}/>

            {stepForm.map((item) => {
              if (step === item.step) {
                const Form = item.form;
                return (
                  <Form
                    key={item.step}
                    setStep={setStep}
                    step={step}
                    formData={formData}
                    updateFormData={updateFormData}
                    handleNextClick={handleNext}
                    isModalOpen={modalOpen}
                    setIsModalOpen={setModalOpen}
                    setCreated={setCreated}
                    orderType="ACS"
                    setShowCreateCustomer={setShowCreateCustomer}
                  />
                );
              }
              return null;
            })}

            {step <= stepForm.length && (
              <Button
                width="full"
                backgroundColor="#422AFB"
                color="white"
                py={6}
                fontWeight="bold"
                _hover={{ backgroundColor: "#3621D3" }}
                onClick={handleNext}
              >
                Next
              </Button>
            )}
          </Box>
          {step !== 1 && (
            <Box height="full" flex="1">
              <OrderSummary />
            </Box>
          )}
        </Flex>
      )}
    </>
  );
};

export default OrderACS;