import { Box, Flex, Circle, Text } from "@chakra-ui/react";

const StepTracker = ({ stepForm, step, setStep }) => {
  // Handle step click only if it's a previous step
  const handleStepClick = (index) => {
    if (index < step) { // Allow only previous steps to be clicked
      setStep(index + 1); // Set the step to the clicked step
    }
  };

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      mb={6}
      ml={2}
      width={step === 1 ? "59%" : "93%"}
      position="relative"
    >
      <Flex alignItems="center" justifyContent="space-between" px={stepForm.length === 4 ? "4rem" : "5.5rem"}>
        {stepForm.map((item, index) => {
          const isCompletedStep = index < step;  // Whether this step is completed
          const isClickable = index < step;     // Only previous steps should be clickable

          return (
            <Flex key={index} alignItems="center" flex={index === stepForm.length - 1 ? '0 0 auto' : '1'}>
              <Circle
                color="white"
                bgColor={isCompletedStep ? "#422AFB" : "gray.300"}
                opacity={isCompletedStep ? 1 : 0.5}
                size="8"
                cursor={isClickable ? "pointer" : "not-allowed"} // Clickable only for previous steps
                onClick={() => isClickable && handleStepClick(index)}  // Handle click only for previous steps
              >
                {index + 1}
              </Circle>

              {index < stepForm.length - 1 && (
                <Box
                  flex="1"
                  height="2px"
                  bgColor={index < step - 1 ? "#422AFB" : "gray.300"}
                  opacity={index < step - 1 ? 1 : 0.5}
                />
              )}
            </Flex>
          );
        })}
      </Flex>

      {/* Step Labels */}
      <Flex width="full" mt={2} justifyContent="space-between">
        {stepForm.map((item, index) => (
          <Box key={index} textAlign="center" flex="1">
            <Text
              fontSize="sm"
              fontWeight="bold"
              color={index < step ? "black" : "gray.300"}
            >
              Step {item.step}
            </Text>
            <Text
              fontSize="sm"
              color={index < step ? "black" : "gray.600"}
              opacity={index < step ? 1 : 0.5}
            >
              {item.name}
            </Text>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default StepTracker;
