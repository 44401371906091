import axios from "axios";
import { API_ROUTES } from "constants/api.constants";
import { BASE_API_URL } from "constants/api.constants";
import removeNullValuesAndFormat from "utils/removeNullAndFormat";

const CONTACT_ID = "533b0919-ac95-9486-8780-65d3e3d65a99";

export async function getDBService(filterObj) {
    try {
      let url = BASE_API_URL + API_ROUTES.GET_DATABASE;
  
      const paramsObj = { ...filterObj, ContactID: CONTACT_ID };
      let params = removeNullValuesAndFormat(paramsObj);
      
      if (params.length) {
        url += "?" + params.join("&");
      }
      
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
        },
      });
      return response;
    } catch (error) {
      if (!error.response) {
        throw new Error("Network error occurred");
      }
      return error.response; 
    }
  }