// Chakra Imports
import { Button, Flex } from "@chakra-ui/react";
import { NavLink as RouterNavLink } from "react-router-dom";
// Custom Components
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React from "react";
// Assets
import routes from "routes.js";
import LeadFocus from "components/modal/LeadModal";
import {
	NavLink,
	useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import TrailFocus from "components/modal/TrailModal";
import EnrollFocus from "components/modal/EnrollModal";
import { LeadFilterMenu } from "components/filter/LeadFilterMenu";
import { OrderFilterMenu } from "components/filter/OrderFilter";
import { InvoicesFilterMenu } from "components/filter/InvoiceFilter";
import { QuotesFilterMenu } from "components/filter/QuotesFilter";
import { TicketsFilterMenu } from "components/filter/TicketsFilter";
import { UsageFilterMenu } from "components/filter/UsageFilter";
import { S3FilterMenu } from "components/filter/S3Filter";
import { VmFilterMenu } from "components/filter/VmFilter";

const AddButtonList = [
	{
		url: "leads",
		component: (
			<LeadFocus title="Add Lead" content="Add a new task to the list" />
		),
	},
	{
		url: "trail",
		component: (
			<TrailFocus title="Add Trail" content="Add a new task to the list" />
		),
	},
	{
		url: "data-tables",
		component: (
			<EnrollFocus title="Enroll" content="Add a new task to the list" />
		),
	},
];

const FilterButtonList = [
	{
		url: "leads",
		component: <LeadFilterMenu />,
	},
	{
		url: "orders",
		component: <OrderFilterMenu />,
	},
	{
		url: "invoices",
		component: <InvoicesFilterMenu />,
	},
	{
		url: "quote",
		component: <QuotesFilterMenu />,
	},
	{
		url: "tickets",
		component: <TicketsFilterMenu />,
	},
	{
		url: "usage",
		component: <UsageFilterMenu />,
	},
	// {
	// 	url: "management",
	// 	component: <InventoryFilterMenu />,
	// },
	{
		url: "s3",
		component: <S3FilterMenu />,
	},
	{
		url: "vm",
		component: <VmFilterMenu />,
	},
];

export default function HeaderLinks(props) {
	const { secondary } = props;
	// Chakra Color Mode

	const Location = useLocation();
	const { pathname } = Location;
	const path = pathname.split("/")[2];

	const AddButton = AddButtonList.find((item) => item.url === path)?.component;
	const FilterButton = FilterButtonList.find(
		(item) => item.url === path
	)?.component;

	return (
		<Flex
			w={{ sm: "100%", md: "auto" }}
			alignItems="center"
			flexDirection="row"
			flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
			p="10px"
			gap="10px"
			borderRadius="30px"
		>
			<SidebarResponsive routes={routes} />
			{FilterButton}
			{AddButton}
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func,
};
