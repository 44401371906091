import axios from "axios";
import { API_ROUTES } from "constants/api.constants";
import { BASE_API_URL } from "constants/api.constants";
import removeNullValuesAndFormat from "utils/removeNullAndFormat";

const CONTACT_ID = "533b0919-ac95-9486-8780-65d3e3d65a99";

export async function getVmService(filterObj) {
  try {
    let url = BASE_API_URL + API_ROUTES.GET_VIRTUAL_MACHINES;

    const paramsObj = { ...filterObj, ContactID: CONTACT_ID };
    let params = removeNullValuesAndFormat(paramsObj);

    if (params.length) {
      url += "?" + params.join("&");
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
      },
    });
    return response;
  } catch (error) {
    if (!error.response) {
      throw new Error("Network error occurred");
    }
    return error.response;
  }
}

export async function getVmProductsService(filterObj) {
  try {
    let url = BASE_API_URL + API_ROUTES.GET_VM_PRODUCTS;
    const paramsObj = { ...filterObj };
    let params = removeNullValuesAndFormat(paramsObj);

    if (params.length) {
      url += "?" + params.join("&");
    }
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
      },
    });
    return response.data.data.vmProducts;
  } catch (error) {
    throw new Error("Network error occurred");
  }
}

export async function getFirewallsService(reqObj) {
  try {
    let url = BASE_API_URL + API_ROUTES.GET_FIREWALLS;

    const paramsObj = { ...reqObj, ContactID: CONTACT_ID };
    let params = removeNullValuesAndFormat(paramsObj);

    if (params.length) {
      url += "?" + params.join("&");
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getVirtualNetworkService(reqObj) {
  try {
    let url = BASE_API_URL + API_ROUTES.GET_VIRTUAL_NETWORKS;

    const paramsObj = { ...reqObj, ContactID: CONTACT_ID };
    let params = removeNullValuesAndFormat(paramsObj);

    if (params.length) {
      url += "?" + params.join("&");
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
      },
    });

    return response;
  } catch (error) {
    return error.response;
  }
}

export const createVMService = async (payload) => {
  try {
    const response = await axios.post(
      BASE_API_URL + API_ROUTES.CREATE_VIRTUAL_MACHINE,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to create VM");
  }
};

export const getVMDiskService = async (vmid) => {
  try {
    const paramsObj = { vmid: vmid };
    let params = removeNullValuesAndFormat(paramsObj);
    let url = BASE_API_URL + API_ROUTES.GET_DISK;

    if (params.length) {
      url += "?" + params.join("&");
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to fetch disks");
  }
};
export const createDiskService = async (vmid, payload) => {
  try {
    const paramsObj = { vmid: vmid };
    let params = removeNullValuesAndFormat(paramsObj);
    let url = BASE_API_URL + API_ROUTES.GET_DISK;

    if (params.length) {
      url += "?" + params.join("&");
    }
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to create disk");
  }
};

export const updateDiskService = async (vmid, payload) => {
  try {
    const paramsObj = { diskid: payload.id, vmid: vmid };
    let params = removeNullValuesAndFormat(paramsObj);
    let url = BASE_API_URL + API_ROUTES.GET_DISK;

    if (params.length) {
      url += "?" + params.join("&");
    }

    const response = await axios.patch(url, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to update disk");
  }
};

export async function deleteDiskService({ diskid, vmid }) {
  try {
    const url = `${BASE_API_URL}/v1/vm/disk`;
    const paramsObj = { diskid: diskid, vmid: vmid };

    const params = new URLSearchParams(paramsObj).toString();

    const response = await axios.delete(`${url}?${params}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
      },
    });

    return response;
  } catch (error) {
    if (!error.response) {
      throw new Error("Network error occurred");
    }
    return error.response;
  }
}

export async function updateVMService(vmid, payload) {
  try {
    const paramsObj = { vmid: vmid };
    let params = removeNullValuesAndFormat(paramsObj);
    let url = BASE_API_URL + API_ROUTES.GET_VIRTUAL_MACHINES;

    if (params.length) {
      url += "?" + params.join("&");
    }

    const response = await axios.patch(url, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Titan_Access")}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to update VM");
  }
}
