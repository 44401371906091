// import toast from "react-hot-toast/dist";
import { useMutation } from "react-query";
import { createTrialService } from "services/apiOrders";

export const useCreateTrail = () => {
	const { isLoading, mutate: createTrail } = useMutation({
		mutationKey: ["createTrial"],
		mutationFn: (trial) => createTrialService(trial),
		onSuccess: (data) => {
			console.log(data);
		},
		onError: () => {
			console.log("error");
		},
	});

	return {
		isLoading,
		createTrail,
	};
};
