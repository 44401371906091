import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { updateDiskService } from "services/apiVm";
import { useHistory } from "react-router-dom";

export const usePatchVMDisk = () => {
  const history = useHistory();

  const { mutate, isLoading, isSuccess, isError } = useMutation({
    mutationFn: (diskData) => updateDiskService(diskData.vmid, diskData.payload),
    onError: (error) => {
      if (error.response?.status === 401) {
        localStorage.removeItem("Titan_Access");
        history.replace("/auth/sign-in");
        toast.error("Unauthorized access. Please login again.");
      } else {
        toast.error(error.response?.data?.message || "Failed to update disk.");
      }
    },
    onSuccess: (res) => {
      if (res.status === 200 || res.status === 201) {
        toast.success("Disk updated successfully!");
      } else {
        toast.error(res.data?.message || "Unexpected error occurred.");
      }
    },
  });

  return { updateDisk: mutate, isLoading, isSuccess, isError };
};
