import React, { useEffect, useState, useRef } from "react";
import {
	Box,
	Button,
	useDisclosure,
	ModalOverlay,
	Modal,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	FormControl,
	FormLabel,
	Input,
	ModalFooter,
	Grid,
	Flex,
} from "@chakra-ui/react";

export default function EnrollFocus(props) {
	const { ...rest } = props;
	const { isOpen, onOpen, onClose } = useDisclosure();

	const initialRef = React.useRef();
	const finalRef = React.useRef();

	const [companyName, setCompanyName] = useState();
	const [email, setEmail] = useState();
	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [phone, setPhone] = useState();
	const [productSKU, setProductSKU] = useState();
	const [title, setTitle] = useState();

	useEffect(() => {
		//
	}, []);

	return (
		<>
			<Button
				onClick={onOpen}
				style={{
					backgroundColor: "#7654ff",
					color: "white",
					borderRadius: "6px",
					padding: "13px 36px",
					height: "auto",
					border: "none",
					cursor: "pointer",
				}}
			>
				{rest.title}
			</Button>

			<Modal
				initialFocusRef={initialRef}
				finalFocusRef={finalRef}
				isOpen={isOpen}
				onClose={onClose}
				size="xl"
				m={4}
				p={3}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Create New Trial</ModalHeader>
					<ModalCloseButton />
					<ModalBody p={5}>
						<Grid templateColumns="repeat(2, 1fr)" gap={3}>
							<FormControl>
								<FormLabel>Company Name</FormLabel>
								<Input
									value={companyName}
									onChange={(e) => setCompanyName(e.target.value)}
									placeholder="Enter Campaign Name"
								/>
							</FormControl>
							<FormControl>
								<FormLabel>Email</FormLabel>
								<Input
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Enter Email Address"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>First Name</FormLabel>
								<Input
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
									placeholder="Enter First Name"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>Last Name</FormLabel>
								<Input
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
									placeholder="Enter Last Name"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>Phone</FormLabel>
								<Input
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
									placeholder="Enter Phone Number"
									inputMode="numeric"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>Product SKU</FormLabel>
								<Input
									value={productSKU}
									onChange={(e) => setProductSKU(e.target.value)}
									placeholder="Enter Product SKU"
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>Type</FormLabel>
								<Input
									value={title}
									onChange={(e) => setTitle(e.target.value)}
									placeholder="Enter Type"
								/>
							</FormControl>
						</Grid>
					</ModalBody>

					<ModalFooter>
						<Flex w="100%" justifyContent="flex-end">
							<Button onClick={onClose} borderRadius={5} p={"10px 30px"} mr={2}>
								Cancel
							</Button>
							<Button
								colorScheme="brand"
								ml={2}
								borderRadius={5}
								p={"10px 40px"}
							>
								Save
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
