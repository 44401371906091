import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  RadioGroup,
  Radio,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  SliderThumb,
  Select,
  Icon,
  Button,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { ReactComponent as DropdownIcon } from "../../../../assets/icons/dropdown.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash.svg";
import { getVmProductsService } from "services/apiVm";
const SystemSetup = ({
  formData,
  updateFormData,
  orderType,
  setStepValidation,
  showErrors,
  setShowErrors,
}) => {
  const [selectedTerm, setSelectedTerm] = useState("");
  const [vCPU, setVCPU] = useState(1);
  const [ram, setRAM] = useState(4);
  const [disks, setDisks] = useState([
    { id: 1, storageType: "Disk Type", capacity: 1, unit: "GB" },
  ]);
  const [vmProducts, setvmProducts] = useState([]);

  const MAX_CAPACITY_GB = 999;
  const MAX_CAPACITY_TB = 10;

  // Fetch the VM products
  const fetchVMProducts = async (params = {}) => {
    const response = await getVmProductsService(params);
    if (response) {
      setvmProducts(response);
    }
  };

  // Handle OS, region, and CPU selections
  const handleOSChange = (e) => {
    const selectedOS = e.target.value;
    updateFormData((prevData) => ({
      ...prevData,
      virtualMachine: {
        ...prevData.virtualMachine,
        operatingSystem: selectedOS,
      },
    }));

    // Fetch regions based on the selected OS
    fetchVMProducts({ os: selectedOS });
  };

  const handleRegionChange = (e) => {
    const selectedRegion = e.target.value;
    updateFormData((prevData) => ({
      ...prevData,
      virtualMachine: {
        ...prevData.virtualMachine,
        region: selectedRegion,
      },
    }));

    // Fetch CPU make based on OS and selected region
    fetchVMProducts({
      os: formData.virtualMachine.operatingSystem,
      region: selectedRegion,
    });
  };

  const handleCpuMakeChange = (e) => {
    const selectedCpuMake = e.target.value;
    updateFormData((prevData) => ({
      ...prevData,
      virtualMachine: {
        ...prevData.virtualMachine,
        cpu: {
          ...prevData.virtualMachine.cpu,
          cpuMake: selectedCpuMake,
        },
      },
    }));

    // Fetch CPU generation based on OS, region, and selected CPU make
    fetchVMProducts({
      os: formData.virtualMachine.operatingSystem,
      region: formData.virtualMachine.region,
      cpuMake: selectedCpuMake,
    });
  };

  const [currentDiskId, setCurrentDiskId] = useState(1);
  const [errors, setErrors] = useState({
    region: "",
    cpuMake: "",
    cpuGeneration: "",
    vCPU: "",
    ram: "",
    storage: "",
    operatingSystem: "",
  });

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    updateFormData((prevData) => {
      const newData = { ...prevData };
      if (name.includes(".")) {
        const [parent, child] = name.split(".");
        newData.virtualMachine = {
          ...newData.virtualMachine,
          [parent]: {
            ...newData.virtualMachine[parent],
            [child]: value,
          },
        };
      } else {
        newData.virtualMachine = {
          ...newData.virtualMachine,
          [name]: value,
        };
      }
      return newData;
    });
  };

  const handleDiskChange = (id, field, value) => {
    const updatedDisks = disks.map((disk) => {
      if (disk.id === id) {
        const updatedDisk = { ...disk, [field]: value };

        if (field === "unit") {
          const maxCapacity =
            value === "GB" ? MAX_CAPACITY_GB : MAX_CAPACITY_TB;
          updatedDisk.capacity = Math.min(disk.capacity, maxCapacity);
        }

        return updatedDisk;
      }
      return disk;
    });
    setDisks(updatedDisks);
    updateFormData((prevData) => ({
      ...prevData,
      virtualMachine: {
        ...prevData.virtualMachine,
        disks: updatedDisks,
      },
    }));
  };
  const addNewDisk = () => {
    const newDisk = {
      id: disks.length + 1,
      storageType: "NvMe",
      capacity: 1,
      unit: "GB",
    };
    setDisks([...disks, newDisk]);
    setCurrentDiskId(newDisk.id);
  };
  const handleDeleteNewDisk = (index) => {
    const updatedNewDisks = disks.filter((_, idx) => idx !== index);
    setDisks(updatedNewDisks);
  };

  const handleNextStepValidation = () => {
    const newErrors = {
      region: formData.virtualMachine?.region ? "" : "Region is required",
      cpuMake: formData.virtualMachine?.cpu?.cpuMake
        ? ""
        : "CPU Make is required",
      cpuGeneration: formData.virtualMachine?.cpu?.cpuGeneration
        ? ""
        : "CPU Generation is required",
      vCPU: formData.virtualMachine?.cpu?.vCPU ? "" : "vCPU is required",
      ram: formData.virtualMachine?.memoryAmount ? "" : "RAM is required",
      storage:
        formData.virtualMachine?.disks?.length > 0
          ? ""
          : "At least one disk is required",
      operatingSystem: formData.virtualMachine?.operatingSystem
        ? ""
        : "Operating System is required",
    };

    setErrors(newErrors);

    const isValid = Object.values(newErrors).every((error) => error === "");
    setStepValidation((prev) => ({ ...prev, 2: isValid }));
  };

  useEffect(() => {
    if (orderType === "VM") {
      handleNextStepValidation();
    }
  }, [formData, orderType]);

  useEffect(() => {
    fetchVMProducts();
  }, []);

  const osOptionsMap = new Map(
    vmProducts.map((product) => {
      const prettyOs = `${product.os} ${product.version} ${product.edition}`;
      const urlFriendly = `${product.os}${product.version.replace(
        /\s|\./g,
        ""
      )}${product.edition.replace(/\s/g, "")}`;
      const urlFriendlyOs = urlFriendly.toLowerCase();
      return [urlFriendlyOs, prettyOs];
    })
  );
  const osOptions = Array.from(osOptionsMap.entries());
  const regionOptions = [
    ...new Set(vmProducts.flatMap((product) => product.regions)),
  ];

  console.log(regionOptions);
  const cpuMakeOptions = [
    ...new Set(vmProducts.map((product) => product.cpuMake)),
  ];
  const cpuGenerationOptions = [
    ...new Set(vmProducts.map((product) => product.cpuGeneration)),
  ];

  return (
    <Box py={5} width="100%">
      <Box>
        {orderType === "ACS" && (
          <>
            <Text fontSize="md" fontWeight="bold" mb={4}>
              Users and Term for {orderType}
            </Text>
            <RadioGroup onChange={setSelectedTerm} value={selectedTerm}>
              <Table variant="simple" mb={6}>
                <Thead bg="#F9F7F7">
                  <Tr>
                    <Th />
                    <Th>Term</Th>
                    <Th>Commitment</Th>
                    <Th>Price/GB</Th>
                  </Tr>
                </Thead>
                <Tbody bg="white">
                  {Array.from({ length: 3 }).map((_, index) => (
                    <Tr key={index}>
                      <Td>
                        <Radio value={`term-${index + 1}`} />
                      </Td>
                      <Td>Term {index + 1}</Td>
                      <Td>Commitment {index + 1}</Td>
                      <Td>${(10 + index).toFixed(2)}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </RadioGroup>
            <Select
              placeholder="Who is going to be the end user?"
              mb={4}
              bg="white"
              width="100%"
              mx="auto"
              _placeholder={{ color: "black", fontWeight: "normal" }}
            />
            <Text fontSize="md" fontWeight="bold" mb={4}>
              S3
            </Text>
            <Flex direction="column" mb={6}>
              <Flex mb={4} justifyContent="space-between" width="100%">
                <Input
                  bg="white"
                  placeholder="Admin contact email"
                  width="48%"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
                <Input
                  bg="white"
                  placeholder="Admin Contact Name"
                  width="48%"
                  _placeholder={{ color: "black", fontWeight: "normal" }}
                />
              </Flex>
              <Input
                bg="white"
                placeholder="Root Username"
                width="100%"
                mb={4}
                _placeholder={{ color: "black", fontWeight: "normal" }}
              />
              <Input
                bg="white"
                placeholder="Desired URL"
                width="100%"
                _placeholder={{ color: "black", fontWeight: "normal" }}
              />
            </Flex>
          </>
        )}
        {orderType === "Database" && (
          <>
            <Text fontSize="md" fontWeight="bold" mb={4}>
              Database Information
            </Text>
            <Flex gap={5}>
              <Select
                placeholder="Database Type"
                mb={3}
                bg="white"
                icon={<Icon as={DropdownIcon} />}
              />
              <Select
                placeholder="Version"
                mb={3}
                bg="white"
                icon={<Icon as={DropdownIcon} />}
              />
            </Flex>
            <Input
              value={"Number of cores (vCPU) - " + vCPU + " cores"}
              isReadOnly
              textAlign="start"
              bgColor="white"
              fontSize="md"
              placeholder="Number of Cores (vCPU)"
              mb={3}
            />
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width="82%"
              mb={6}
            >
              <Text mr={2}>1</Text>
              <Slider
                value={vCPU}
                min={1}
                max={8}
                step={1}
                onChange={(val) => setVCPU(val)}
                colorScheme="purple"
                width="85%"
              >
                <SliderTrack bg="purple.100">
                  <SliderFilledTrack bg="#422AFB" />
                </SliderTrack>
                <SliderThumb boxSize={5} />
              </Slider>
              <Text mr={2}>8</Text>
            </Flex>
            <Text fontSize="md" fontWeight="bold" mb={3}>
              Memory and Storage
            </Text>
            <Flex
              mb={2}
              flexDirection={["column", "row"]}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box mb={[2, 0]}>RAM</Box>
              <Input
                value={ram + "GB"}
                bg="white"
                width={["100%", "40%", "30%", "20%"]}
              />
            </Flex>

            <Flex
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              mb={6}
            >
              <Text mr={2}>1</Text>
              <Slider
                value={ram}
                min={1}
                max={64}
                step={1}
                onChange={(val) => {
                  setRAM(val);
                  updateFormData((prevData) => ({
                    ...prevData,
                    virtualMachine: {
                      ...prevData.virtualMachine,
                      memoryAmount: val + "GB",
                    },
                  }));
                }}
                colorScheme="purple"
                width="70%"
              >
                <SliderTrack bg="purple.100">
                  <SliderFilledTrack bg="#422AFB" />
                </SliderTrack>
                <SliderThumb boxSize={5} />
              </Slider>
              <Text mr={2}>64</Text>
            </Flex>

            <Select
              placeholder="Select Storage Class"
              mb={3}
              bg="white"
              icon={<Icon as={DropdownIcon} />}
            />
          </>
        )}
        {orderType === "VM" && (
          <>
            <Text fontSize="md" fontWeight="bold" mb={4}>
              Details
            </Text>
            <FormControl isInvalid={!!errors.operatingSystem && showErrors}>
              <Select
                placeholder="Select Operating System"
                name="operatingSystem"
                value={formData.virtualMachine.operatingSystem || ""}
                onChange={handleOSChange}
                bg="white"
                mb={2}
                isDisabled={osOptions.length === 0}
              >
                {osOptions.map(([urlFriendlyOs, prettyOs], index) => (
                  <option key={index} value={urlFriendlyOs}>
                    {prettyOs}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.operatingSystem}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.region && showErrors}>
              <Select
                name="virtualMachine.region"
                value={formData.virtualMachine?.region || ""}
                onChange={handleRegionChange}
                placeholder="Select Region"
                bg="white"
                mb={2}
                isDisabled={!formData.virtualMachine.operatingSystem}
              >
                {regionOptions.map((region, index) => (
                  <option key={index} value={region}>
                    {region}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.region}</FormErrorMessage>
            </FormControl>
            <Text fontSize="md" fontWeight="bold" my={4}>
              CPU Information
            </Text>
            <FormControl isInvalid={!!errors.cpuMake && showErrors}>
              <Select
                name="cpu.cpuMake"
                value={formData.virtualMachine?.cpu?.cpuMake || ""}
                onChange={handleCpuMakeChange}
                placeholder="Select CPU Make"
                bg="white"
                mb={2}
                isDisabled={!formData.virtualMachine.region}
              >
                {cpuMakeOptions.map((cpuMake, index) => (
                  <option key={index} value={cpuMake}>
                    {cpuMake}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.cpuMake}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.cpuGeneration && showErrors}>
              <Select
                name="cpu.cpuGeneration"
                value={formData.virtualMachine?.cpu?.cpuGeneration || ""}
                onChange={handleFieldChange}
                placeholder="Select CPU Generation"
                bg="white"
                mb={2}
                isDisabled={!formData.virtualMachine.cpu?.cpuMake}
              >
                {cpuGenerationOptions.map((cpuGen, index) => (
                  <option key={index} value={cpuGen}>
                    {cpuGen}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.cpuGeneration}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.vCPU && showErrors}>
              <Flex
                mb={2}
                flexDirection={["column", "row"]}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box mb={[2, 0]}>Number of cores (vCPU)</Box>
                <Input
                  value={`${vCPU} cores`}
                  bg="white"
                  isReadOnly
                  width={["100%", "40%", "30%", "20%"]}
                />
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                mb={2}
              >
                <Text mr={2}>1</Text>
                <Slider
                  value={vCPU}
                  min={1}
                  max={8}
                  step={1}
                  onChange={(val) => {
                    setVCPU(val);
                    updateFormData((prevData) => ({
                      ...prevData,
                      virtualMachine: {
                        ...prevData.virtualMachine,
                        cpu: {
                          ...prevData.virtualMachine.cpu,
                          vCPU: val,
                        },
                      },
                    }));
                  }}
                  colorScheme="purple"
                  width="85%"
                >
                  <SliderTrack bg="purple.100">
                    <SliderFilledTrack bg="#422AFB" />
                  </SliderTrack>
                  <SliderThumb boxSize={5} />
                </Slider>
                <Text mr={2}>8</Text>
              </Flex>
              <FormErrorMessage>{errors.vCPU}</FormErrorMessage>
            </FormControl>

            <Text fontSize="md" fontWeight="bold" mb={4}>
              Memory
            </Text>
            <FormControl isInvalid={!!errors.ram && showErrors}>
              <Flex
                mb={2}
                flexDirection={["column", "row"]}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box mb={[2, 0]}>RAM</Box>
                <Input
                  value={ram + "GB"}
                  bg="white"
                  width={["100%", "40%", "30%", "20%"]}
                  isReadOnly
                />
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                mb={2}
              >
                <Text mr={2}>1</Text>
                <Slider
                  value={ram}
                  min={1}
                  max={64}
                  step={1}
                  onChange={(val) => {
                    setRAM(val);
                    updateFormData((prevData) => ({
                      ...prevData,
                      virtualMachine: {
                        ...prevData.virtualMachine,
                        memoryAmount: val + "GB",
                      },
                    }));
                  }}
                  colorScheme="purple"
                  width="70%"
                >
                  <SliderTrack bg="purple.100">
                    <SliderFilledTrack bg="#422AFB" />
                  </SliderTrack>
                  <SliderThumb boxSize={5} />
                </Slider>
                <Text mr={2}>64</Text>
              </Flex>
              <FormErrorMessage>{errors.ram}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.storage && showErrors}>
              <Text fontSize="md" fontWeight="bold" mb={4}>
                Storage
              </Text>
              {disks.map((disk, index) => (
                <Flex key={disk.id} mb={3} gap={3} alignItems="center">
                  <Select
                    placeholder="Disk Type"
                    mr={2}
                    bg="white"
                    icon={<Icon as={DropdownIcon} />}
                    value={disk.storageType}
                    width="50%"
                    onChange={(e) =>
                      handleDiskChange(disk.id, "storageType", e.target.value)
                    }
                  >
                    <option value="NvMe">NvMe</option>
                    <option value="SSD">SSD</option>
                  </Select>
                  <Input
                    value={`${disk.capacity} ${disk.unit}`}
                    onClick={() => {
                      setCurrentDiskId(disk.id);
                    }}
                    isReadOnly
                    bg="white"
                    width="50%"
                    border="none"
                    focusBorderColor="transparent"
                  />
                  <Box
                    bg="white"
                    borderRadius={5}
                    p={2.5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => handleDeleteNewDisk(index)}
                  >
                    <Icon as={TrashIcon} w={5} h={5} />
                  </Box>
                </Flex>
              ))}

              <Button
                mb={4}
                bgColor="#CCDAFF"
                color="black"
                size="md"
                fontSize="sm"
                borderRadius="md"
                _hover={{ bgColor: "#CCDAFF" }}
                onClick={addNewDisk}
              >
                Add new Disk
              </Button>
              {disks.length > 0 && (
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  mb={6}
                >
                  <Text mr={2}>1</Text>
                  <Slider
                    value={
                      disks.find((d) => d.id === currentDiskId)?.capacity || 1
                    }
                    min={1}
                    max={
                      disks.find((d) => d.id === currentDiskId)?.unit === "GB"
                        ? MAX_CAPACITY_GB
                        : MAX_CAPACITY_TB
                    }
                    step={1}
                    onChange={(value) =>
                      handleDiskChange(currentDiskId, "capacity", value)
                    }
                    colorScheme="purple"
                    width="70%"
                  >
                    <SliderTrack bg="purple.100">
                      <SliderFilledTrack bg="#422AFB" />
                    </SliderTrack>
                    <SliderThumb boxSize={5} />
                  </Slider>
                  <Text mr={2}>
                    {disks.find((d) => d.id === currentDiskId)?.unit === "GB"
                      ? MAX_CAPACITY_GB
                      : MAX_CAPACITY_TB}
                  </Text>
                  <Select
                    value={
                      disks.find((d) => d.id === currentDiskId)?.unit || "GB"
                    }
                    onChange={(e) =>
                      handleDiskChange(currentDiskId, "unit", e.target.value)
                    }
                    bg="white"
                    width="80px"
                    icon={<Icon as={DropdownIcon} />}
                  >
                    <option value="GB">GB</option>
                    <option value="TB">TB</option>
                  </Select>
                </Flex>
              )}
              <FormErrorMessage>{errors.storage}</FormErrorMessage>
            </FormControl>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SystemSetup;
