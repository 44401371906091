import {
  Box,
  Flex,
  Icon,
  Link,
  Text,
  Button,
  Select,
  Input,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  SliderThumb,
  useDisclosure,
} from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { ReactComponent as DropdownIcon } from "../../../../assets/icons/dropdown.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash.svg";
import { useState } from "react";
import DiskModal from "../components/DiskModal";
import toast from "react-hot-toast";
import { usePostVMDisk } from "features/Vm/Disk/usePostVMDisk";
import { usePatchVMDisk } from "features/Vm/Disk/usePatchVMDisk";
import { useDeleteVMDisk } from "features/Vm/Disk/useDeleteVMDisk";
import { usePatchVM } from "features/Vm/usePatchVirtualMachines";

const VmDetails = ({ data = {}, onBack }) => {
  const [disks, setDisks] = useState(data.disks || []);
  const [newDisks, setNewDisks] = useState([]);
  const [modifiedDisks, setModifiedDisks] = useState({});
  const [cpuCores, setCpuCores] = useState(data.cpu.vCPU || 1);
  const [ram, setRam] = useState(data.memoryAmount || 4);
  const [sliderValue, setSliderValue] = useState("1");
  const [selectedDiskIndex, setSelectedDiskIndex] = useState(null);
  const [selectedDiskType, setSelectedDiskType] = useState(null);
  const [isCreatingDisks, setIsCreatingDisks] = useState(false);
  const [isUpdatingDisks, setIsUpdatingDisks] = useState(false);
  const [storageUnit, setStorageUnit] = useState("GB");

  const { createDisk } = usePostVMDisk();
  const { updateDisk } = usePatchVMDisk();
  const { deleteVMDisk } = useDeleteVMDisk();
  const { updateVM } = usePatchVM();

  const handleCreateDisk = async () => {
    if (newDisks.some((disk) => !disk.capacity || !disk.storageType)) {
      return toast.error("Please fill out all fields for the new disks");
    }

    setIsCreatingDisks(true);
    const vmid = data.vmid;

    for (const diskData of newDisks) {
      await createDisk(vmid, diskData);
      toast.success(
        `Disk with capacity ${diskData.capacity}GB created successfully`
      );
    }

    setNewDisks([]);
    setIsCreatingDisks(false);
  };

  const handleUpdateDisk = async () => {
    setIsUpdatingDisks(true);
    const vmid = data.vmid;

    for (const [diskId, diskData] of Object.entries(modifiedDisks)) {
      await updateDisk({ vmid, payload: { id: diskId, ...diskData } });
    }

    setModifiedDisks({});
    setIsUpdatingDisks(false);
  };

  const handleDeleteDisk = async (index) => {
    try {
      await deleteVMDisk({ diskid: index ,vmid: data.vmid });
    } catch (error) {
      console.error("Error deleting disk:", error);
    }
  };

  const handleDiskChange = (index, field, value) => {
    const updatedDisks = [...disks];
    if (field === "capacity") {
      value = `${value}${storageUnit}`;
    }
    updatedDisks[index][field] = value;
    setDisks(updatedDisks);

    const diskId = updatedDisks[index].id;
    setModifiedDisks((prev) => ({
      ...prev,
      [diskId]: { ...prev[diskId], [field]: value },
    }));
  };

  const handleAddDisk = () => {
    setNewDisks([...newDisks, { storageType: "SSD", capacity: "" }]);
  };

  const handleDeleteNewDisk = (index) => {
    const updatedNewDisks = newDisks.filter((_, idx) => idx !== index);
    setNewDisks(updatedNewDisks);
  };

  const handleNewDiskChange = (index, field, value) => {
    const updatedNewDisks = [...newDisks];
    if (field === "capacity") {
      value = `${value}${storageUnit}`;
    }
    updatedNewDisks[index] = {
      ...updatedNewDisks[index],
      [field]: value,
    };
    setNewDisks(updatedNewDisks);
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
    const stringValue = `${value}${storageUnit}`;
    if (selectedDiskType === 'existing') {
      const updatedDisks = [...disks];
      updatedDisks[selectedDiskIndex].capacity = stringValue;
      setDisks(updatedDisks);

      const diskId = updatedDisks[selectedDiskIndex].id;
      setModifiedDisks(prev => ({
        ...prev,
        [diskId]: { ...prev[diskId], capacity: stringValue }
      }));
    } else if (selectedDiskType === 'new') {
      const updatedNewDisks = [...newDisks];
      updatedNewDisks[selectedDiskIndex].capacity = stringValue;
      setNewDisks(updatedNewDisks);
    }
  };

  const handleUpdateRules = async () => {
    await handleCreateDisk();
    await handleUpdateDisk();
    await updateVM({
      vmid: data.vmid,
      payload: {
        ram: ram,
        vcpu: cpuCores,
      },
    });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Box position="absolute" top="3rem">
        <Link py={1} display="flex">
          {"Dashboard → Management → "}
          <Text fontWeight="bold">
            Virtual Machines → Account {data.accountNumber}
          </Text>
        </Link>
        <Box display="flex" alignItems="center">
          <Link
            href="#"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={onBack}
          >
            <Box
              backgroundColor="#363853"
              borderRadius="50%"
              p={1.5}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon as={MdArrowBack} color="white" w={5} h={5} />
            </Box>
          </Link>
          <Text fontWeight="bold" fontSize="35px" ml={3} color={"#363853"}>
            Management - Virtual Machines
          </Text>
        </Box>
      </Box>
      <Flex justifyContent="space-between" py={4}>
        <Box width="50%" pr={20}>
          <Box mb={5}>
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              Account Information
            </Text>
            <Flex mb={2} justifyContent="space-between">
              <Text>Account Number</Text>{" "}
              <Text ml={2}>{data.accountNumber}</Text>
            </Flex>
            <Flex mb={2} justifyContent="space-between">
              <Text>Name</Text> <Text ml={2}>{data["Name"]}</Text>
            </Flex>
            <Flex mb={2} justifyContent="space-between">
              <Text>Region</Text> <Text ml={2}>{data.region}</Text>
            </Flex>
          </Box>
          <Box mb={5}>
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              CPU Information
            </Text>
            <Flex mb={2} justifyContent="space-between" alignItems="center">
              <Text>CPU Make</Text>
              <Input
                value={data.cpu.cpuMake}
                onChange={(e) =>
                  console.log("CPU Make Changed", e.target.value)
                }
                textAlign="center"
                bg="white"
                cursor="default"
                borderRadius={5}
                width="23%"
              />
            </Flex>
            <Flex mb={2} justifyContent="space-between" alignItems="center">
              <Text>CPU Generation</Text>
              <Input
                value={data.cpu.cpuGeneration}
                onChange={(e) =>
                  console.log("CPU Generation Changed", e.target.value)
                }
                textAlign="center"
                bg="white"
                cursor="default"
                py={1}
                borderRadius={5}
                width="23%"
              />
            </Flex>
            <Flex mb={2} justifyContent="space-between" alignItems="center">
              <Text>CPU Cores</Text>
              <Input
                value={cpuCores}
                onChange={(e) =>
                  console.log("CPU Cores Changed", e.target.value)
                }
                textAlign="center"
                cursor="default"
                bg="white"
                borderRadius={5}
                width="23%"
              />
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              pt={2}
            >
              <Text mr={2}>
                {data.allowedSpecs ? data.allowedSpecs.minCPU : 1}
              </Text>
              <Slider
                value={cpuCores}
                min={data.allowedSpecs ? data.allowedSpecs.minCPU : 1}
                max={data.allowedSpecs ? data.allowedSpecs.maxCPU : 16}
                step={1}
                onChange={(val) => setCpuCores(val)}
                colorScheme="purple"
                width="60%"
              >
                <SliderTrack bg="purple.100">
                  <SliderFilledTrack bg="#422AFB" />
                </SliderTrack>
                <SliderThumb boxSize={5} />
              </Slider>
              <Text ml={2}>
                {data.allowedSpecs ? data.allowedSpecs.maxCPU : 16}
              </Text>
            </Flex>
          </Box>
          <Box mb={5}>
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              Miscellaneous Information
            </Text>
            <Flex mb={2} justifyContent="space-between">
              <Text>Operating System</Text>{" "}
              <Text ml={2}>{data.operatingSystem}</Text>
            </Flex>
          </Box>
        </Box>
        <Box width="50%">
          <Box mb={5}>
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              Memory Information
            </Text>
            <Flex mb={2} justifyContent="space-between" alignItems="center">
              <Text width="95%">RAM</Text>{" "}
              <Input
                value={ram + " GB"}
                onChange={(e) => console.log("RAM Changed", e.target.value)}
                bg="white"
                textAlign="center"
                borderRadius={5}
                width="-moz-fit-content"
              />
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" pt={2}>
              <Text mr={2}>
                {data.allowedSpecs ? data.allowedSpecs.minRam : 1} GB
              </Text>
              <Slider
                value={ram}
                min={data.allowedSpecs ? data.allowedSpecs.minRam : 1}
                max={data.allowedSpecs ? data.allowedSpecs.maxRam : 64}
                step={1}
                onChange={(val) => setRam(val)}
                colorScheme="purple"
                width="60%"
              >
                <SliderTrack bg="purple.100">
                  <SliderFilledTrack bg="#422AFB" />
                </SliderTrack>
                <SliderThumb boxSize={5} />
              </Slider>
              <Text ml={2}>
                {data.allowedSpecs ? data.allowedSpecs.maxRam : 64} GB
              </Text>
            </Flex>
          </Box>
          <Box mb={5}>
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              Storage Information
            </Text>
            {disks.map((disk, index) => (
              <Flex
                key={index}
                mb={2}
                gap={4}
                justifyContent="space-between"
                alignItems="center"
              >
                <Select
                  value={disk.storageType}
                  onChange={(e) =>
                    handleDiskChange(index, "storageType", e.target.value)
                  }
                  bg="white"
                  borderRadius={5}
                  width="full"
                  disabled
                  icon={<DropdownIcon />}
                >
                  <option value="NVMe">NVMe</option>
                </Select>
                <Input
                  value={disk.capacity}
                  onChange={(e) =>
                    handleDiskChange(index, "capacity", e.target.value)
                  }
                  onClick={() => {
                    setSelectedDiskIndex(index);
                    setSelectedDiskType("existing");
                    setSliderValue(disk.capacity.toString());
                  }}
                  bg="white"
                  borderRadius={5}
                  width="full"
                />
                <Box
                  bg="white"
                  borderRadius={5}
                  p={2.5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => handleDeleteDisk(disk.id)}
                >
                  <Icon as={TrashIcon} w={5} h={5} />
                </Box>
              </Flex>
            ))}
            {newDisks.map((disk, index) => (
              <>
                <Flex
                  key={index}
                  mb={2}
                  gap={4}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Select
                    value={disk.storageType}
                    onChange={(e) =>
                      handleNewDiskChange(index, "storageType", e.target.value)
                    }
                    bg="white"
                    borderRadius={5}
                    width="full"
                    icon={<DropdownIcon />}
                  >
                    <option value="NVMe">NVMe</option>
                    <option value="SSD">SSD</option>
                  </Select>
                  <Input
                    value={disk.capacity}
                    onChange={(e) =>
                      handleNewDiskChange(index, "capacity", e.target.value.replace(storageUnit, ''))
                    }
                    onClick={() => {
                      setSelectedDiskIndex(index);
                      setSelectedDiskType('new');
                      setSliderValue((disk.capacity || "0").toString());
                    }}
                    bg="white"
                    borderRadius={5}
                    width="full"
                    placeholder={`Capacity ${storageUnit}`}
                  />
                  <Box
                    bg="white"
                    borderRadius={5}
                    p={2.5}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => handleDeleteNewDisk(index)}
                  >
                    <Icon as={TrashIcon} w={5} h={5} />
                  </Box>

                </Flex>

              </>
            ))}

            <Flex justifyContent="space-between" alignItems="center" pt={2}>
              <Text mr={2}>
                {data.allowedSpecs ? data.allowedSpecs.minStorage : 1}{" "}
                {storageUnit}
              </Text>
              <Slider
                value={Number(sliderValue)}
                min={data.allowedSpecs ? data.allowedSpecs.minStorage : 1}
                max={data.allowedSpecs ? data.allowedSpecs.maxStorage : 1024}
                step={1}
                onChange={handleSliderChange}
                colorScheme="purple"
                width="60%"
              >
                <SliderTrack bg="purple.100">
                  <SliderFilledTrack bg="#422AFB" />
                </SliderTrack>
                <SliderThumb boxSize={5} />
              </Slider>
              <Text mr={2}>
                {data.allowedSpecs ? data.allowedSpecs.maxStorage : 1024}{" "}
                {storageUnit}
              </Text>
              <Select
                value={storageUnit}
                onChange={(e) => setStorageUnit(e.target.value)}
                bg="white"
                width="80px"
                icon={<Icon as={DropdownIcon} />}
              >
                <option value="GB">GB</option>
                <option value="TB">TB</option>
              </Select>
            </Flex>
            <Flex mt={2} width="full">
              <Button
                bg="#EDEBFF"
                color="#422AFB"
                _hover={{ bg: "#EDEBFF" }}
                _active={{ bg: "#d2c7ff" }}
                variant="solid"
                mr={2}
                borderRadius={8}
                width="full"
                fontSize="sm"
                onClick={handleAddDisk}
              >
                Add New Disk
              </Button>
              <Button
                bg="white"
                color="#422AFB"
                border="1px solid #5c18e1"
                _hover={{ bg: "#f3e8ff" }}
                _active={{ bg: "#e1d5ff" }}
                variant="outline"
                borderRadius={8}
                width="full"
                fontSize="sm"
                onClick={onOpen}
              >
                View All Disks
              </Button>
              <DiskModal
                isOpen={isOpen}
                onClose={onClose}
                disks={disks}
                handleDiskChange={handleDiskChange}
                handleDeleteDisk={handleDeleteDisk}
                handleAddDisk={handleAddDisk}
              />
            </Flex>
          </Box>
          <Box mb={5}>
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              Virtual Machine Setup Cost
            </Text>
            <Flex mb={2} justifyContent="space-between">
              <Text>Region ({data.region}) - </Text>{" "}
            </Flex>
            <Flex mb={2} justifyContent="space-between">
              <Text>Bandwidth</Text> <Text ml={2}>{data["Bandwidth"]}</Text>
            </Flex>
          </Box>
        </Box>
      </Flex>

      <Button
        variant="solid"
        size="md"
        px={"1.2rem"}
        width="full"
        py={"1.4rem"}
        borderRadius={5}
        color="white"
        fontWeight="light"
        bg="#422AFB"
        _hover={{ bg: "#422AFB" }}
        mt={4}
        onClick={handleUpdateRules}
        isLoading={isCreatingDisks || isUpdatingDisks}
        loadingText={
          isCreatingDisks ? "Creating Disks..." : "Updating Disks..."
        }
      >
        Update Configuration
      </Button>
    </Box>
  );
};

export default VmDetails;
