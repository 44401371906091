export const BASE_API_URL =
  process.env.REACT_APP_API_URL || "https://jarvis.orgosys.com/titan";
  // https://titan.orgosys.com/titan
export const API_ROUTES = {
  LOGIN: "/v1/login",
  VERIFY: "/v1/verify",
  CREATE_LEAD: "/v1/lead",
  CREATE_TRIAL: "/v1/orders/trial",
  CREATE_ENROLL: "/v1/orders/enroll",
  GET_ORDERS: "/v1/orders",
  GET_QUOTES: "/v1/orders/quote",
  GET_ORDERQUOTE: "/v1/order-quote",
  GET_INVOICES: "/v1/orders/invoices",
  GET_USAGES: "/v1/billing/usage",
  GET_ACCOUNTS: "/v1/accounts/tickets",
  GET_INVENTORY: "/v1/inventory",
  CREATE_ORDER: "/v1/order/sign-up",
  UPLOAD_FILE: "/v1/upload",
  GET_ENROLLMENT: "/v1/order/sign-up",
  CREATE_ENROLLMENT: "/v1/enrollment/sign-up",
  GET_LOCATION: "/v1/geo-location",
  CHECK_AVAILABILITY: "/v1/desired-url",
  CHECK_ACCOUNT: "/v1/customer",
  GET_DATABASE:"/v1/database",
  GET_VIRTUAL_MACHINES: "/v1/vm",
  GET_VM_PRODUCTS:"/v1/vm/product",
  CREATE_VIRTUAL_MACHINE:"/v1/vm",
  GET_FIREWALLS: "/v1/vm/firewall",
  GET_VIRTUAL_NETWORKS: "/v1/vm/virtual-network",
  GET_DISK:"/v1/vm/disk"
};

export const INTERNAL_SERVER_ERROR_MESSAGE = "Internal server error";

/**
 * [GIN-debug] GET    /titan/v1/health-check    --> titan/internal/app/controller/healthcheck.IHealthCheckController.HealthCheck-fm (15 handlers)
[GIN-debug] POST   /titan/v1/login           --> titan/internal/app/controller/oauth.IOAuthController.Login-fm (15 handlers)
[GIN-debug] GET    /titan/v1/verify          --> titan/internal/app/controller/oauth.IOAuthController.Verify-fm (15 handlers)
[GIN-debug] POST   /titan/v1/lead            --> titan/internal/app/controller/titan.ITitanController.CreateLead-fm (16 handlers)
[GIN-debug] POST   /titan/v1/orders/trial    --> titan/internal/app/controller/titan.ITitanController.CreateTrial-fm (16 handlers)
[GIN-debug] POST   /titan/v1/orders/enroll   --> titan/internal/app/controller/titan.ITitanController.CreateEnroll-fm (16 handlers)
[GIN-debug] GET    /titan/v1/orders          --> titan/internal/app/controller/titan.ITitanController.GetOrders-fm (16 handlers)
[GIN-debug] GET    /titan/v1/orders/quote    --> titan/internal/app/controller/titan.ITitanController.GetQuotes-fm (16 handlers)
[GIN-debug] GET    /titan/v1/orders/invoices --> titan/internal/app/controller/titan.ITitanController.GetInvoices-fm (16 handlers)
[GIN-debug] GET    /titan/v1/billing/usage   --> titan/internal/app/controller/titan.ITitanController.GetUsages-fm (16 handlers)
[GIN-debug] GET    /titan/v1/accounts/tickets --> titan/internal/app/controller/titan.ITitanController.GetAccounts-fm (16 handlers)
 */
